import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import CheckoutForm from "./CheckoutForm";
import { CREATE_PAYMENT_LAMBDA_URL } from "./consts";
import Spinner from "./Spinner";
import img from "./imgs/img.png";
import fomento from "./logos-coles/fomento.png";
import { useLocation } from "react-router-dom";

function Checkout({ stripePromise }) {
  const [clientSecret, setClientSecret] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [loading, setLoading] = useState(true);
  const { state } = useLocation();

  const { userData } = state; // Read values passed on state

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    locale: "es",
    appearance,
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(CREATE_PAYMENT_LAMBDA_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        paymentId: paymentId,
        school: userData.school,
        fullStudentName: userData.fullStudentName,
        studentEmail: userData.studentEmail,
        email: userData.email,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
        setPaymentId(data.paymentId);
        setLoading(false);
      });
  }, []);

  return (
    <div className="App">
      {loading ? (
        <Spinner /> // Render the spinner component while loading
      ) : (
        clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <div className="fomento">
              <style type="text/css">
                {`
        body {
            margin: 0;
            padding: 0;
            font-family: Avenir,Helvetica,Arial,sans-serif;
            color: #2c3e50;
            background-color: #fff;
            float: left;
            width: 100%;
        }
        h1 {
            text-align: center;
            font-size: 38px;
            margin: 10px 0;
        }
        .num {
            display: inline-block;
            width:50px;
            height: 50px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            border-radius: 50px;
            background-color:#a5c2c6;
            line-height: 50px;
            text-align: center;
            margin-right: 20px;
        }
        .fomento-logo {display: block; width:100%; float: left; text-align: center; margin-top: 15px;}
        .eel-title {display: block; width:100%; float: left; text-align: center;}
        .first-step {display: block; width:100%; float: left; text-align: center;}
        .first-step p {font-size: 18px; margin: 0;}
        .schools {display: block; width:80%; float: left; margin: 40px 10%;}
        .school {display: inline-block; width:20%; float: left; text-align: center;}
        .school a {
            display: block;
            text-decoration: none;
            color: #727272;
            font-size: 12px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
            padding: 10px 0 5px 0;
        }
        .school a:hover {background-color: #c7dfe2}
        .help {display: block;float: left;width: 100%; margin: 0 0 20px 0 ; text-align: center;}
        .actions {
            display: block; width:100%; float: left; text-align: center; margin-top: 15px;
        }
        .image {
            display: block; width: 45%; float: left; border-right: 1px solid #ccc; min-height: 500px; 
        }
        .image img {
            display: block;
            width:450px;
            background-color: blue;
            float: right;
            margin-right: 10%;
        }
        .steps {
            display: block; width: 48%; float: left; text-align: left; margin-left: 2%;
        }
        .steps p {font-size: 18px; margin: 0 0 10px 0;}
        .btn-comprar {
            text-align: center;
            background-color: green;
            font-size: 20px;
            text-decoration: none;
            color: white;
            padding: 15px 120px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
            margin-top: 30px;
            display: block;
            width: 100%;
        }
        .second-step input, textarea {
            display: inline- block;
            font-size: 14px;
            padding: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            margin-bottom: 10px;
            border: 1px solid #ccc;
            margin-left: 10px;
        }
        .w170 {
            width: 160px;
        }
        .w350 {
            width: 350px;
        }
        .third-step {
            float: left;
            display: block;
            margin-top: 20px;
        }
        .third-step input {
            display: block;
            font-size: 16px;
            padding: 15px;
            width: 350px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
            margin-bottom: 10px;
            border: 1px solid #ccc;
            margin-left: 70px;
        }
        .inpts {
            display: block;
            width: 100%;
            float: left;
            margin-left: 60px;
            margin-bottom: 10px; 
        }         
`}
              </style>
              <div className="fomento-logo">
                <img src={fomento} height="90" width="" alt="fomento" />
              </div>

              <div className="eel-title">
                <h1>English Exams Lab</h1>
              </div>

              <div className="actions">
                <div className="image">
                  <img src={img} alt="Price list" />
                </div>
                <div className="steps">
                  <div className="fourth-step">
                    <p>
                      <span className="num">4</span> Rellene los datos de pago para formalizar la compra:
                    </p>
                    <CheckoutForm paymentIntentClientSecret={clientSecret} paymentId={paymentId} userData={userData} />
                  </div>
                </div>
              </div>
            </div>
          </Elements>
        )
      )}
    </div>
  );
}

export default Checkout;
